import { apiClient } from './client'
import { LoginArgs } from '~~/src/types/Auth'

export const postConfirmEmail = (token: string): Promise<LoginArgs> => {
    return apiClient('/users/confirm-email', {
        method: 'POST',
        body: {
            token
        }
    })
}
